@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --tw-custom-gradient-start: rgba(37, 53, 17, 1);
    --tw-custom-gradient-mid1: rgba(102, 126, 68, 0.5);
    --tw-custom-gradient-mid2: rgba(99, 122, 66, 0.7);
    --tw-custom-gradient-mid3: rgba(96, 119, 62, 0.9);
    --tw-custom-gradient-end: rgba(178, 34, 34, 0.8);

    --tw-custom-gradient-bmid1: rgba(153, 153, 0, 0.8);
    --tw-custom-gradient-bmid2: rgba(160, 160, 0, 0.8);
    --tw-custom-gradient-bmid3: rgba(170, 170, 0, 0.8);
    --tw-custom-gradient-bend: rgba(180, 180, 0, 0.8);

    --tw-custom-gradient-wmid1: rgba(0, 25, 51, 1);
    --tw-custom-gradient-wmid2: rgba(0, 38, 77, 0.9);
    --tw-custom-gradient-wmid3: rgba(0, 51, 102, 0.8);
    --tw-custom-gradient-wend: rgba(0, 64, 128, 0.7);
}
